import React, { useEffect, useState } from 'react';

export type BaseContextType = {
  freeShippingCO: boolean;
  setFreeShippingCO: React.Dispatch<React.SetStateAction<boolean>>;
  calloutVisible: boolean;
  setCalloutVisible: React.Dispatch<React.SetStateAction<boolean>>;
  cartShowDesktop: boolean;
  setCartShowDesktop: React.Dispatch<React.SetStateAction<boolean>>;
  productShowCart: boolean;
  setProductShowCart: React.Dispatch<React.SetStateAction<boolean>>;
  dismissSideCart: () => void;
  sideCartDismissed: boolean;
};

export const BaseContext = React.createContext<BaseContextType>({
  freeShippingCO: false,
  setFreeShippingCO: (val) => val,
  calloutVisible: false,
  setCalloutVisible: (val) => val,
  cartShowDesktop: false,
  setCartShowDesktop: (val) => val,
  productShowCart: false,
  setProductShowCart: (val) => val,
  dismissSideCart: () => null,
  sideCartDismissed: false,
});

const BaseProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [freeShippingCO, setFreeShippingCO] = useState(true);
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [cartShowDesktop, setCartShowDesktop] = useState(false);
  const [productShowCart, setProductShowCart] = useState(false);
  const [sideCartDismissed, setSideCartDismissed] = useState(false);

  const dismissSideCart = () => {
    localStorage.setItem('side-cart-dismissed', 'true');
    setProductShowCart(false);
    setSideCartDismissed(true);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isSideCartDismissed =
        localStorage.getItem('side-cart-dismissed') || 'false';
      const savedSideCartDismissed = JSON.parse(isSideCartDismissed);
      setSideCartDismissed(savedSideCartDismissed);
    }
  }, []);

  return (
    <BaseContext.Provider
      value={{
        freeShippingCO,
        setFreeShippingCO,
        calloutVisible,
        setCalloutVisible,
        cartShowDesktop,
        setCartShowDesktop,
        productShowCart,
        setProductShowCart,
        dismissSideCart,
        sideCartDismissed,
      }}
    >
      {children}
    </BaseContext.Provider>
  );
};

export default BaseProvider;

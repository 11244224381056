import { round } from 'lodash';
import type {
  ApplicableDiscounts,
  AppliedTotals,
  CartPayload,
  CartProduct,
  CartProducts,
  CartTotalMap,
  Coupon,
  Discount,
  FormattedTotals,
  OldCartTotalMap,
  OrderLineItem,
} from './schemas';

import { roundTo } from './utils';

const DISCOUNT_APPLIES_TO_FILTER = 'order';
const VALUE_SUBSCRIPTION_IDS = ['vm-pl', 'vm-pn', 'vm-gp'];
const TAX_RATE = 0.07;

const SHIPPING_METHODS = {
  Regular: {
    price: 12.99,
  },
  'FREE-Regular': {
    price: 0,
  },
};

/**
 * Applies final discounts to the calculated order total
 */
function applyInPlaceConclusiveDiscounts(
  totals: CartTotalMap,
  discounts: ApplicableDiscounts
): void {
  for (const discount of discounts.conclusive) {
    const { equipmentDiscountSavings, equipmentSubtotal } = totals;
    const { discountAmount: dAmount, minimumOrderTotal } = discount;

    // skip the discount if the order total is less than the minimum
    const meetsMinimumTotal = equipmentSubtotal >= minimumOrderTotal;
    if (!discount.discountActive || !meetsMinimumTotal) {
      continue;
    }

    let orderTotal = equipmentSubtotal;
    switch (discount.discountType) {
      case 'fixed':
        orderTotal = equipmentSubtotal - dAmount;
        break;

      case 'percentage':
        orderTotal = calculatePercentDiscount(equipmentSubtotal, dAmount);
        break;

      default:
        // don't apply modification if discountType is unknown
        continue;
    }

    let appliedDiscount = roundTo(equipmentSubtotal - orderTotal);

    // ensure the order total is not less than the minimum order
    if (orderTotal < discount.minimumOrderTotalAfterDiscount) {
      orderTotal = discount.minimumOrderTotalAfterDiscount;
      appliedDiscount = roundTo(equipmentSubtotal - orderTotal);
    }

    totals.equipmentSubtotal = roundTo(orderTotal);
    totals.equipmentDiscountSavings = roundTo(
      equipmentDiscountSavings + appliedDiscount
    );

    totals.appliedDiscounts.push(discount.id);
    totals.appliedDiscountMap[discount.id] = appliedDiscount;

    // break if the discount is exclusive
    if (discount.exclusiveDiscount) {
      break;
    }
  }
}

/**
 * Applies Cove Credit to all applicable product totals
 */
function applyInPlaceCoveCreditDiscounts(
  totals: CartTotalMap,
  coveCredit: number
): void {
  if (coveCredit === 0) {
    // nothing to do
    return;
  }

  let appliedCredit = coveCredit;
  // ensure the order total is not less than the minimum order
  const creditToApply = totals.equipmentSubtotal - coveCredit;
  const orderTotal = Math.max(creditToApply, 0);
  if (orderTotal === 0) {
    appliedCredit = totals.equipmentSubtotal;
  }

  totals.equipmentCreditApplied = roundTo(appliedCredit);
  totals.equipmentSubtotal = roundTo(orderTotal);
}

/**
 * Applies product line item discounts to the order line items
 */
function applyInPlaceCumulativeDiscounts(
  equipmentLineItems: Record<string, OrderLineItem>,
  discounts: ApplicableDiscounts
): void {
  const cartSkus = Object.keys(equipmentLineItems);
  for (const selectedDiscount of discounts.cumulative) {
    if (!(selectedDiscount.active && selectedDiscount.discountActive)) {
      continue;
    }

    const productSkus =
      selectedDiscount.restrictTo.length > 0
        ? selectedDiscount.restrictTo
            .filter(it => cartSkus.includes(it))
            .map(it => it)
        : cartSkus;

    switch (selectedDiscount.appliesTo) {
      case 'product':
        applyInPlaceProductDiscounts(
          equipmentLineItems,
          selectedDiscount,
          productSkus,
          discounts
        );
        break;
    }

    const overrideDiscounts = selectedDiscount.overrideDiscounts || [];
    const overrideIds = overrideDiscounts.map(it => it.id);
    if (selectedDiscount.discountActive && overrideIds.length > 0) {
      discounts.cumulative.forEach((discount, index) => {
        if (overrideIds.includes(discount.id)) {
          discounts.cumulative[index].discountActive = false;
        }
      });
    }
  }
}

/**
 * Applies free months for the monitoring plan to cart totals
 */
function applyInPlaceFreeMonthsDiscounts(
  totals: CartTotalMap,
  discounts: ApplicableDiscounts
): void {
  // filter all discounts to find ones that are active and have free months
  // sort by free months in descending order so the highest free months is first
  const allDiscounts = discounts.cumulative
    .concat(discounts.conclusive)
    .filter(it => it.active && it.discountActive)
    .filter(it => it.freeMonths > 0)
    .sort((a, b) => b.freeMonths - a.freeMonths);

  if (allDiscounts.length > 0 && allDiscounts[0].freeMonths > 0) {
    totals.monitoringFreeMonths = allDiscounts[0].freeMonths;
    totals.appliedDiscounts.push(allDiscounts[0].id);
  }
}

/**
 *
 */
function applyInPlaceMonitoringDiscounts(
  monitoringLineItems: Record<string, OrderLineItem>,
  discounts: ApplicableDiscounts
): void {
  // There may not be any monitoring discounts to apply
  // Free months are applied to the order in the calculateFreeMonths function
  return;
}

/**
 * Applies final cart totals and tax calculations to the cart totals
 */
function applyInPlaceOrderFinalTotals(
  totals: CartTotalMap,
  cart: CartPayload
): void {
  // 0 percent tax rates are allowed and should be accepted when provided
  const eqTaxRate = cart.equipmentTaxRate ?? cart.taxRate ?? TAX_RATE;
  const mnTaxRate = cart.monitoringTaxRate ?? cart.taxRate ?? TAX_RATE;
  const shippingMethod = cart.shippingMethod || 'FREE-Regular';

  const {
    equipmentCreditApplied,
    equipmentDiscountSavings,
    equipmentLineItemSavings,
    equipmentRetailSubtotal,
    equipmentSaleSavings,
    equipmentSubtotal,
    monitoringRetailSubtotal,
    monitoringSubtotal,
  } = totals;

  const eqRetailTaxes = roundTo(equipmentRetailSubtotal * eqTaxRate);
  const eqRetailTotal = roundTo(equipmentRetailSubtotal + eqRetailTaxes);
  const mnRetailTaxes = roundTo(monitoringRetailSubtotal * mnTaxRate);
  const mnRetailTotal = roundTo(monitoringRetailSubtotal + mnRetailTaxes);
  const equipmentTaxes = roundTo(equipmentSubtotal * eqTaxRate);
  const equipmentTotal = roundTo(equipmentSubtotal + equipmentTaxes);
  const monitoringTaxes = roundTo(monitoringSubtotal * mnTaxRate);
  const monitoringTotal = roundTo(monitoringSubtotal + monitoringTaxes);
  const shippingTotal = roundTo(SHIPPING_METHODS[shippingMethod].price);

  const orderRetailTotal = roundTo(
    eqRetailTotal + mnRetailTotal + shippingTotal
  );

  // exclude monitoring total from total due if free months are applied
  const orderTotalDue =
    totals.monitoringFreeMonths > 0
      ? roundTo(equipmentTotal + shippingTotal)
      : roundTo(equipmentTotal + monitoringTotal + shippingTotal);

  totals.equipmentTotalSavings = roundTo(
    equipmentSaleSavings +
      equipmentLineItemSavings +
      equipmentDiscountSavings +
      equipmentCreditApplied
  );

  totals.monitoringRetailTaxes = mnRetailTaxes;
  totals.monitoringRetailTotal = mnRetailTotal;
  totals.equipmentRetailTaxes = eqRetailTaxes;
  totals.equipmentRetailTotal = eqRetailTotal;
  totals.monitoringTaxes = monitoringTaxes;
  totals.monitoringTotal = monitoringTotal;
  totals.equipmentTaxes = equipmentTaxes;
  totals.equipmentTotal = equipmentTotal;
  totals.orderRetailTotal = orderRetailTotal;
  totals.orderTotalDue = orderTotalDue;
  totals.shippingTotal = shippingTotal;
}

/**
 * Determines whether a discount is applied by an active coupon code.
 */
function applyCouponDiscountActivation(
  promotions: string[],
  discount: Discount,
  coupons: Coupon[]
): boolean {
  let discountActive = false;
  for (const promotion of promotions) {
    const coupon = coupons.find(it => it.couponCode === promotion);
    const discountIds = (coupon?.discounts || []).map(it => it.id);
    discountActive = discountIds.includes(discount.id);
    if (discountActive) {
      break;
    }
  }

  return discountActive;
}

/**
 * Determines whether a product discount conditions apply to cart contents.
 */
function applyProductDiscountActivation(
  cartProducts: CartProduct[],
  discount: Discount
): boolean {
  const conditionProducts = discount.conditionProducts || [];
  const conditionProductsQuantifier =
    discount.conditionProductsQuantifier || 'any';

  if (conditionProductsQuantifier === 'all') {
    const hasAllConditionProducts = conditionProducts.every(sku =>
      cartProducts.find(it => it.sku === sku)
    );

    return conditionProducts.length > 0 && hasAllConditionProducts;
  }

  const hasSomeConditionsProducts = cartProducts.some(cartProduct =>
    conditionProducts.includes(cartProduct.sku)
  );

  if (conditionProductsQuantifier === 'none') {
    return !hasSomeConditionsProducts;
  }

  if (conditionProductsQuantifier === 'any') {
    return conditionProducts.length === 0 || hasSomeConditionsProducts;
  }

  return false;
}

/**
 * discounts that apply to individual order products
 */
function applyInPlaceProductDiscounts(
  equipmentLineItems: Record<string, OrderLineItem>,
  selectedDiscount: Discount,
  productSkus: string[],
  discounts: ApplicableDiscounts
): void {
  let eligibleTotal = 0;
  for (const sku of productSkus) {
    eligibleTotal += equipmentLineItems[sku].salePriceTotal;
  }

  let noDiscountToApply = false;
  const totalAmount = eligibleTotal;
  for (const aDiscount of selectedDiscount.applyAfterDiscounts || []) {
    const matched = discounts.cumulative.find(
      it =>
        it.discountType === 'fixed' &&
        it.appliesTo === 'order' &&
        it.id === aDiscount.id &&
        it.discountActive &&
        it.active
    );

    if (eligibleTotal && matched) {
      if (matched.minimumOrderTotalAfterDiscount < eligibleTotal) {
        const motad = matched.minimumOrderTotalAfterDiscount;
        const difference = eligibleTotal - motad;
        eligibleTotal = Math.max(0, difference);
        break;
      } else {
        noDiscountToApply = true;
        break;
      }
    }
  }

  if (noDiscountToApply) {
    return;
  }

  for (const sku of productSkus) {
    if (!equipmentLineItems[sku]) {
      continue;
    }

    switch (selectedDiscount.discountType) {
      case 'fixed':
        applyInPlaceProductFixedDiscount(
          equipmentLineItems[sku],
          selectedDiscount
        );
        break;

      case 'percentage':
        applyInPlaceProductPercentDiscount(
          equipmentLineItems[sku],
          selectedDiscount,
          eligibleTotal,
          totalAmount
        );
        break;
    }

    // TODO: implement discount.removeFromBase, removeFromBaseQty.
    // if (selectedDiscount.removeFromBase) {
    //   //  products[sku].baseTotal = products[sku].baseTotal - (
    //   //    products[sku].total - (
    //   //      products[sku].discountPrice * products[sku].qty)) || 0
    //   const prod =
    //     equipmentLineItems[sku].discountPrice *
    //     equipmentLineItems[sku].quantity;
    //   const diff = equipmentLineItems[sku].total - prod;
    //   const base = equipmentLineItems[sku].baseTotal - diff;
    //   equipmentLineItems[sku].baseTotal = Math.max(0, base);
    // }

    // if (selectedDiscount.removeFromBaseQty) {
    //   const baseQty =
    //     selectedDiscount.maxQuantity ?? equipmentLineItems[sku].baseQuantity;
    //   const adjustedQty = equipmentLineItems[sku].baseQuantity - baseQty;
    //   equipmentLineItems[sku].baseQuantity = Math.max(0, adjustedQty);
    // }

    // const pTotal =
    //   equipmentLineItems[sku].discountPrice * equipmentLineItems[sku].quantity;
    // equipmentLineItems[sku].total = roundTo(Math.max(0, pTotal));
  }
}

/**
 * Calculates fixed product discount given a product line item and discount
 */
function applyInPlaceProductFixedDiscount(
  lineItem: OrderLineItem,
  discount: Discount
): void {
  if (lineItem.appliedDiscounts.includes(discount.id)) {
    return; // discount already applied
  }

  const { quantity } = lineItem;
  const { discountAmount, maxQuantity: maxQ } = discount;
  const multiplier = maxQ && quantity > maxQ ? maxQ : quantity;
  const discountTotal = discountAmount * multiplier;
  const dSpread = roundTo(discountTotal / quantity);

  const dAmount = roundTo(lineItem.discountSavingsEach + dSpread);

  let dSavingsEach = dAmount;
  let dAppliedAmount = dSpread * quantity;
  if (dAmount > lineItem.salePriceEach) {
    // discount is greater than the sale price, apply the sale price
    dSavingsEach = lineItem.salePriceEach;
    dAppliedAmount = dSavingsEach * quantity;
  }

  const dSavingsTotal = roundTo(dSavingsEach * quantity);

  lineItem.discountSavingsEach = dSavingsEach;
  lineItem.discountSavingsTotal = dSavingsTotal;
  lineItem.lineTotal = roundTo(lineItem.salePriceTotal - dSavingsTotal);
  lineItem.appliedDiscounts = [...lineItem.appliedDiscounts, discount.id];
  lineItem.appliedDiscountMap[discount.id] = dAppliedAmount;
  lineItem.discountMap = {
    ...lineItem.discountMap,
    [discount.id]: Math.max(0, discountTotal),
  };
}

/**
 * Calculates percentage type product discount given a product and discount
 */
function applyInPlaceProductPercentDiscount(
  lineItem: OrderLineItem,
  discount: Discount,
  eligibleTotal: number,
  amountTotal: number
): void {
  if (lineItem.appliedDiscounts.includes(discount.id)) {
    return; // discount already applied
  }

  const { quantity, salePriceEach } = lineItem;
  const { discountAmount, maxQuantity: maxQ } = discount;
  const multiplier = maxQ && quantity > maxQ ? maxQ : quantity;
  const discountTotal = (discountAmount / 100) * salePriceEach * multiplier;
  const dSpread = roundTo(discountTotal / quantity);

  const dAmount = roundTo(lineItem.discountSavingsEach + dSpread);

  let dSavingsEach = dAmount;
  let dAppliedAmount = dSpread * quantity;
  if (dAmount > lineItem.salePriceEach) {
    // discount is greater than the sale price, apply the sale price
    dSavingsEach = lineItem.salePriceEach;
    dAppliedAmount = dSavingsEach * quantity;
  }

  const dSavingsTotal = roundTo(dSavingsEach * quantity);

  lineItem.discountSavingsEach = dSavingsEach;
  lineItem.discountSavingsTotal = dSavingsTotal;
  lineItem.lineTotal = roundTo(lineItem.salePriceTotal - dSavingsTotal);
  lineItem.appliedDiscounts = [...lineItem.appliedDiscounts, discount.id];
  lineItem.appliedDiscountMap[discount.id] = dAppliedAmount;
  lineItem.discountMap = {
    ...lineItem.discountMap,
    [discount.id]: Math.max(0, lineItem.dAppliedAmount),
  };
}

/**
 * Sets the product quantity to never exceed the maximum allowed on site.
 */
function setProductQuantity(
  catalogProduct: CartProduct,
  cartProduct: CartProduct,
  skipMaxQty: boolean
): number {
  let maxQuantity = cartProduct.qty || 0;
  if (!skipMaxQty && catalogProduct.maximumSensorCountOnSite) {
    maxQuantity = catalogProduct.maximumSensorCountOnSite;
  }

  // newMaxQuantity must be 0 or greater
  const newMaxQuantity = Math.max(maxQuantity, 0);
  return Math.min(newMaxQuantity, cartProduct.qty);
}

/**
 * Assembles invoice line items for non-subscription products.
 * Calculates the total price for each product line item.
 * Does not calculate line item discounts.
 */
function buildEquipmentLineItems(
  cart: CartPayload,
  skipMaxQty: boolean
): Record<string, OrderLineItem> {
  const orderLineItems: Record<string, OrderLineItem> = {};
  if (!cart.cartProducts || !cart.products) return {};
  for (const cartProduct of cart.cartProducts) {
    // make sure the product exists in the catalog, if not skip
    if (!cart.products[cartProduct.sku]) {
      continue;
    }

    // exclude subscription products from the order line items
    if (cart.products[cartProduct.sku].productType === 'subscription') {
      continue;
    }

    const sku = cartProduct.sku;
    const cProduct = cart.products[sku];
    const newQuantity = setProductQuantity(cProduct, cartProduct, skipMaxQty);
    // sale price each is set to the sale price if non-zero, otherwise price
    // TODO: determine whether salePrice should be used at all, looks like no.
    // const salePriceEach = roundTo(cProduct.salePrice || cProduct.price || 0);
    const salePriceEach = roundTo(cProduct.price || 0);
    const salePriceTotal = roundTo(salePriceEach * newQuantity);

    orderLineItems[sku] = {
      appliedDiscounts: [], // discounts are applied in a later step
      appliedDiscountMap: {}, // discounts are applied in a later step
      discountSavingsEach: 0, // discounts are applied in a later step
      discountSavingsTotal: 0, // discounts are applied in a later step
      retailPriceEach: roundTo(cProduct.price || 0),
      retailPriceTotal: roundTo((cProduct.price || 0) * newQuantity),
      salePriceEach: salePriceEach,
      salePriceTotal: salePriceTotal,
      lineTotal: salePriceTotal,
      baseQuantity: newQuantity,
      quantity: newQuantity,
      sku,
    };

    if (cProduct.productType === 'addon') {
      orderLineItems[sku].retailPriceEach = 0;
      orderLineItems[sku].retailPriceTotal = 0;
      orderLineItems[sku].salePriceEach = 0;
      orderLineItems[sku].salePriceTotal = 0;
      orderLineItems[sku].lineTotal = 0;
    }
  }

  return orderLineItems;
}

/**
 * Assembles invoice line items for subscription products.
 * Calculates the total price for each subscription line item.
 */
function buildMonitoringLineItems(
  cart: CartPayload,
  skipMaxQty: boolean = false
): Record<string, OrderLineItem> {
  if (!cart.products) return {};
  const subscriptionSkus = Object.values(cart.products)
    .filter(it => it.productType === 'subscription')
    .map(it => it.sku);

  const subscriptions = Object.values(cart.cartProducts).filter(it =>
    subscriptionSkus.includes(it.sku)
  );

  if (subscriptions.length > 1) {
    throw new Error('Expected exactly one subscription product in the cart');
  }

  const monitoringLineItems: Record<string, OrderLineItem> = {};
  for (const subscription of subscriptions) {
    const cProduct = cart.products[subscription.sku];
    const newQuantity = setProductQuantity(cProduct, subscription, false);
    // sale price each is set to the sale price if non-zero, otherwise price
    const salePriceEach = roundTo(cProduct.salePrice || cProduct.price || 0);
    const salePriceTotal = roundTo(salePriceEach * newQuantity);

    monitoringLineItems[subscription.sku] = {
      appliedDiscounts: [], // discounts are applied in a later step
      appliedDiscountMap: {}, // discounts are applied in a later step
      discountSavingsEach: 0, // discounts are applied in a later step
      discountSavingsTotal: 0, // discounts are applied in a later step
      retailPriceEach: roundTo(cProduct.price || 0),
      retailPriceTotal: roundTo((cProduct.price || 0) * newQuantity),
      salePriceEach: salePriceEach,
      salePriceTotal: salePriceTotal,
      baseQuantity: newQuantity,
      quantity: newQuantity,
      lineTotal: salePriceTotal,
      sku: subscription.sku,
    };
  }

  return monitoringLineItems;
}

/**
 * Applies Percentage type discount to order line item.
 *
 * dPrice     (IDP) the existing discount price on the product
 * dAmount    (DPA) the discount percentage amount
 *            (FDP) the final discount price applied to the product
 *
 * Formula: FDP = (IDP * (100 - DPA)) / 100;
 */
function calculatePercentDiscount(dPrice: number, dAmount: number): number {
  // item.discountPrice =
  //   (item.discountPrice * (100 - discount.discountAmount)) / 100;

  const diff = 100 - dAmount;
  const prod = dPrice * diff;
  return roundTo(prod / 100);
}

/**
 * Find and sort all applicable discounts for the given cart contents.
 *
 * `cart.discounts` is a list of discount objects containing the discount type
 * and parameters defining how the discount is applied.
 *
 * Function returns categorized, filtered, and sorted discounts:
 * `conclusive` contains discounts applied at the end of the totals calculation
 * `cumulative` contains discounts applied to each line item of the order
 * `promotions` contains applied promotion (coupon) codes
 */
function findAndActivateApplicableDiscounts(
  cart: CartPayload
): ApplicableDiscounts {
  // TODO: change the DatoCMS query to only return active discounts, coupons
  // contains array of available and active discounts data queried from DatoCMS
  const allDiscounts = cart.discounts.filter(it => it.active);
  // contains array of available and active coupons data queried from DatoCMS
  const allCoupons = cart.coupons.filter(it => it.active);
  // contains array of coupons data for codes entered by the customer
  const addedCoupons = allCoupons.filter(it =>
    cart.couponCodes.includes(it.couponCode)
  );

  // apply coupon code limitations - up to 2 % off, unlimited fixed
  const MAX_PERCENTAGE_DISCOUNTS = 2;

  // contains a single exclusive coupon data object, if any are exclusive
  const exclusiveCoupon = addedCoupons.find(it => it.active && it.exclusive);
  const promotions: string[] = [];
  if (exclusiveCoupon) {
    promotions.push(exclusiveCoupon.couponCode);
  } else {
    let totalPercentageDiscounts = 0;
    for (const coupon of addedCoupons) {
      // find all percentage type discounts associated with the added coupon
      const pDiscounts = allDiscounts.filter(it => {
        const discountIds = coupon.discounts.map(it => it.id);
        return it.discountType === 'percentage' && discountIds.includes(it.id);
      });

      let hasPercentageDiscounts = false;
      if (pDiscounts.length > 0) {
        totalPercentageDiscounts += pDiscounts.length;
        hasPercentageDiscounts = true;
      }

      // if max percentageDiscounts reached then don't apply the current coupon
      const maxReached = totalPercentageDiscounts > MAX_PERCENTAGE_DISCOUNTS;
      if (!hasPercentageDiscounts || (hasPercentageDiscounts && !maxReached)) {
        promotions.push(coupon.couponCode);
      }
    }
  }

  for (const discount of allDiscounts) {
    discount.discountActive = false;
    switch (discount.activateCondition) {
      case 'couponCode':
        discount.discountActive = applyCouponDiscountActivation(
          promotions,
          discount,
          allCoupons
        );
        break;

      case 'product':
        discount.discountActive = applyProductDiscountActivation(
          cart.cartProducts,
          discount
        );
        break;

      case 'test':
        discount.discountActive = true;
        break;

      default:
        // unknown case, default false
        discount.discountActive = false;
        break;
    }

    const overrideDiscountIds = (discount.overrideDiscounts || []).map(
      it => it.id
    );

    if (discount.discountActive && overrideDiscountIds.length > 0) {
      allDiscounts
        .filter(it => overrideDiscountIds.includes(it.id))
        .map(it => (it.discountActive = false));
    }
  }

  // filter discounts into separate groups based on when to apply them
  // sort discounts in order based on priority
  const conclusive = allDiscounts
    .filter(it => it.appliesTo === DISCOUNT_APPLIES_TO_FILTER)
    .sort((a, b) => b.minimumOrderTotal - a.minimumOrderTotal);

  const cumulative = allDiscounts
    .filter(it => it.appliesTo !== DISCOUNT_APPLIES_TO_FILTER)
    .sort((a, b) => a.priority - b.priority);

  // return applicable discounts and applicable promotion codes
  return { conclusive, cumulative, promotions };
}

/**
 * Initializes cart totals map with default properties and values
 */
function initializeCartTotals(): CartTotalMap {
  return {
    appliedDiscounts: [], // = list of discount ids applied to the order
    appliedDiscountMap: {}, // = map of discount ids to applied discount amount

    // equipment line item totals
    equipmentLineItemsTotal: 0, // = sum of lineTotal for all equipment line items
    equipmentSaleSavings: 0, // = sum of Eq Retail Subtotal - Eq Sales Subtotal
    equipmentLineItemSavings: 0, // = sum of discountSavingsTotal for all line items
    equipmentDiscountSavings: 0, // = discount amount applied to the equipment subtotal
    equipmentCreditApplied: 0, // = cove credit applied to the equipment subtotal
    equipmentTotalSavings: 0, // = sum of equipmentSaleSavings, equipmentLineItemSavings, equipmentDiscountSavings, equipmentCreditApplied
    equipmentRetailSubtotal: 0, // = sum of retailPriceTotal for all line items
    equipmentRetailTaxes: 0, // = calculated tax amount for equipmentRetailTotal
    equipmentRetailTotal: 0, // = sum of equipmentRetailSubtotal and equipmentRetailTaxes
    equipmentSubtotal: 0, // = sum of lineTotal for each equipment line item - equipmentDiscountSavings - equipmentCreditApplied
    equipmentTaxes: 0, // = calculated tax amount for equipmentSubtotal
    equipmentTotal: 0, // = sum of equipmentSubtotal and equipmentTaxes

    // monitoring totals
    monitoringFreeMonths: 0, // = number of free months applied to the monitoring plan
    monitoringRetailSubtotal: 0, // = sum of retailPriceTotal for all monitoring line items
    monitoringRetailTaxes: 0, // = calculated tax amount for monitoringRetailSubtotal
    monitoringRetailTotal: 0, // = sum of monitoringRetailSubtotal
    monitoringSubtotal: 0, // = lineTotal for the monitoring plan line item
    monitoringTaxes: 0, // = calculated tax amount for monitoringSubtotal
    monitoringTotal: 0, // = sum of monitoringSubtotal and monitoringTaxes

    // shipping total
    shippingTotal: 0, // = calculated shipping cost

    // order totals
    orderRetailTotal: 0, // = equipmentRetailTotal + monitoringRetailTotal + shippingTotal
    orderTotalDue: 0, // = monitoringTotal + equipmentTotal + shippingTotal
  };
}

/**
 * Calculates and applies totals for the entire order
 */
function applyInPlaceEquipmentSubTotals(
  totals: CartTotalMap,
  equipmentLineItems: Record<string, OrderLineItem>
): void {
  const appliedDiscounts: string[] = [...totals.appliedDiscounts];
  for (const sku of Object.keys(equipmentLineItems)) {
    const lineItem = equipmentLineItems[sku];
    totals.equipmentRetailSubtotal = roundTo(
      totals.equipmentRetailSubtotal + lineItem.retailPriceTotal
    );
    totals.equipmentSubtotal = roundTo(
      totals.equipmentSubtotal + lineItem.lineTotal
    );
    totals.equipmentLineItemSavings = roundTo(
      totals.equipmentLineItemSavings + lineItem.discountSavingsTotal
    );
    appliedDiscounts.push(...lineItem.appliedDiscounts);
  }

  totals.appliedDiscounts = [...new Set(appliedDiscounts)];
}

/**
 * Calculates and applies totals for the entire order
 */
function applyInPlaceMonitoringSubTotals(
  totals: CartTotalMap,
  monitoringLineItems: Record<string, OrderLineItem>
): void {
  const appliedDiscounts: string[] = [...totals.appliedDiscounts];
  for (const sku of Object.keys(monitoringLineItems)) {
    const lineItem = monitoringLineItems[sku];
    totals.monitoringRetailSubtotal = roundTo(
      totals.monitoringRetailSubtotal + lineItem.retailPriceTotal
    );
    totals.monitoringSubtotal = roundTo(
      totals.monitoringSubtotal + lineItem.lineTotal
    );
    appliedDiscounts.push(...lineItem.appliedDiscounts);
  }

  totals.appliedDiscounts = [...new Set(appliedDiscounts)];
}

/**
 * Validates the cart payload to not include any unknown product SKUs
 */
function validateIncomingCartPayload(cart: CartPayload) {
  if (!cart.promotionDiscounts) {
    // Ensure that promotionDiscounts is initialized. This field was renamed
    // from `promoCodeDiscounts`, set to promoCodeDiscounts value if it exists.
    cart.promotionDiscounts = cart.promoCodeDiscounts || [];
  }

  if (!cart.couponCodes) {
    // Ensure the couponCodes field is an array.
    cart.couponCodes = [];
  }

  if (typeof cart.couponCodes === 'string') {
    // Ensure the couponCodes is not a string.
    cart.couponCodes = [cart.couponCodes];
  }

  if (!Array.isArray(cart.cartProducts)) {
    // Ensure cartProducts exists
    cart.cartProducts = [];
  }

  if (!cart.coupons) {
    // Ensure the coupons array is an array
    cart.coupons = [];
  }

  // TODO: fix this code to validate the cart payload
  // const skus = Object.keys(cart.products);
  // const diff = cart.cartProducts.filter((it) => !skus.includes(it.sku));

  // if (diff.length > 0) {
  //   const error = new BadRequest('ValidationError');
  //   error.errors = [
  //     {
  //       path: 'cartProducts',
  //       errorCode: 'additionalProperties.openapi.requestValidation',
  //       message: `should NOT have [${diff.join(',')}] properties`,
  //       location: 'body'
  //     }
  //   ];
  //   throw error;
  // }
}

/** EXPORTS */

/**
 * Calculate cart totals given a cart payload.
 */
export function calculateTotals(
  cart: CartPayload,
  coveCredit = 0,
  { skipMaxQty = false } = {}
): AppliedTotals {
  validateIncomingCartPayload(cart);
  /* construct order line items, includes line item totals sans discount */
  const monitoringLineItems = buildMonitoringLineItems(cart, skipMaxQty);
  const equipmentLineItems = buildEquipmentLineItems(cart, skipMaxQty);
  /* find and set activation status on all applicable discounts */
  const discounts = findAndActivateApplicableDiscounts(cart);

  /* apply active discounts to order line items */
  applyInPlaceMonitoringDiscounts(monitoringLineItems, discounts);
  applyInPlaceCumulativeDiscounts(equipmentLineItems, discounts);

  /* calculate order totals before applying conclusive discounts */
  const totals = initializeCartTotals();
  applyInPlaceMonitoringSubTotals(totals, monitoringLineItems);
  applyInPlaceEquipmentSubTotals(totals, equipmentLineItems);

  /* apply conclusive discounts to order total */
  applyInPlaceConclusiveDiscounts(totals, discounts);

  /* apply free months */
  applyInPlaceFreeMonthsDiscounts(totals, discounts);

  /* apply cove credit */
  applyInPlaceCoveCreditDiscounts(totals, coveCredit);

  /* calculate final order totals */
  applyInPlaceOrderFinalTotals(totals, cart);

  /****** construct the cart totals return object *****************************/
  const orderLineItems = { ...equipmentLineItems, ...monitoringLineItems };
  const monitoringPlanSku =
    Object.keys(monitoringLineItems).length > 0
      ? Object.keys(monitoringLineItems)[0]
      : null;

  const isValue = monitoringPlanSku
    ? VALUE_SUBSCRIPTION_IDS.includes(monitoringPlanSku)
    : false;

  /****** legacy data shape compatibility *************************************/

  /* rebuild cartProducts object */
  const cartProducts: CartProducts = {};
  for (const sku of Object.keys(orderLineItems)) {
    const cProduct = cart.cartProducts.find(it => it.sku === sku);
    const lineItem = orderLineItems[sku];
    const product = cart.products[sku];

    const { discountSavingsEach, salePriceEach } = lineItem;

    cartProducts[sku] = {
      appliedDiscounts: lineItem.appliedDiscounts,
      discountMap: lineItem.appliedDiscountMap,
      baseQty: lineItem.baseQuantity,
      baseTotal: lineItem.salePriceTotal,
      discountAmount: discountSavingsEach,
      discountPrice: roundTo(salePriceEach - discountSavingsEach),
      equipmentId: product.equipmentId,
      locations: cProduct?.locations || [],
      name: product.name,
      pandoId: product.pandoId,
      price: lineItem.retailPriceEach,
      productType: product.productType,
      qty: lineItem.quantity,
      retailTotal: lineItem.retailPriceTotal,
      salesItemId: product.salesItemId,
      salesItemName: product.salesItemName,
      total: lineItem.lineTotal,
      sku,
      // !DEPRECATED: Always 0, tax is calculated on the equipment total
      baseTotalWithTax: 0,
      // !DEPRECATED: Always 0, coveCredit is applied to the equipment total
      coveCreditApplied: 0,
      // !DEPRECATED: Always 0, grandTotal is not calculated for each line item
      grandTotal: 0,
      // !DEPRECATED: Always false, payLater is no longer supported
      payLaterEligible: false,
      // !DEPRECATED: Always 0, tax is calculated on the equipment total
      taxAmount: 0,
    };
  }

  const orderSubTotal =
    totals.monitoringFreeMonths === 0
      ? roundTo(totals.equipmentSubtotal + totals.monitoringSubtotal)
      : totals.equipmentSubtotal;

  const orderTotalTaxes =
    totals.monitoringFreeMonths === 0
      ? roundTo(totals.equipmentTaxes + totals.monitoringTaxes)
      : totals.equipmentTaxes;

  /* map new CartTotalsMap to OldCartTotalsMap */
  const monitoringPlan = monitoringPlanSku
    ? cartProducts[monitoringPlanSku]
    : null;

  const oldTotalsMap: OldCartTotalMap = {
    allEquipmentGrandTotal: 0, // not calculating tax amounts for retail
    coveCreditAppliedTotal: totals.equipmentCreditApplied,
    equipmentBaseTotal: totals.equipmentRetailSubtotal,
    equipmentCreditAppliedTotal: totals.equipmentCreditApplied,
    equipmentGrandTotal: totals.equipmentTotal,
    equipmentRetailTotal: totals.equipmentRetailSubtotal,
    equipmentRetailTotalTax: totals.equipmentRetailTaxes,
    equipmentRetailTotalWithTax: totals.equipmentRetailTotal,
    equipmentTotal: totals.equipmentSubtotal,
    equipmentTotalTax: totals.equipmentTaxes,
    equipmentTotalWithTax: totals.equipmentTotal,
    monitoringBaseTotal: totals.monitoringSubtotal,
    monitoringRetailTotal: totals.monitoringSubtotal,
    monitoringRetailTotalTax: totals.monitoringRetailTaxes,
    monitoringRetailTotalWithTax: totals.monitoringRetailTotal,
    monitoringTotal: totals.monitoringSubtotal,
    monitoringTotalTax: totals.monitoringTaxes,
    monitoringTotalWithTax: totals.monitoringTotal,
    orderBaseTotal: totals.equipmentSubtotal,
    orderGrandTotal: totals.orderTotalDue,
    orderRetailTotal: roundTo(
      totals.equipmentRetailSubtotal + totals.monitoringRetailSubtotal
    ),
    orderRetailTotalTax: roundTo(
      totals.equipmentRetailTaxes + totals.monitoringRetailTaxes
    ),
    orderRetailTotalWithTax: totals.orderRetailTotal,
    orderTotal: orderSubTotal,
    orderTotalDiscount: totals.equipmentTotalSavings,
    orderTotalTax: orderTotalTaxes,
    orderTotalWithTax: totals.orderTotalDue,
    shippingTotal: totals.shippingTotal,
  };

  /* build formatted currency values */
  const formattedTotals: FormattedTotals = {};
  for (const key of Object.keys(oldTotalsMap)) {
    formattedTotals[key] = String(roundTo(oldTotalsMap[key]).toFixed(2));
  }

  /* Return AppliedTotals */
  return {
    // TODO: optimize this to remove duplicates in the functions
    appliedDiscounts: [...new Set(totals.appliedDiscounts)],
    cartItemIds: Object.values(orderLineItems).map(it => it.sku),
    cartProducts,
    formattedTotals,
    freeMonths: totals.monitoringFreeMonths,
    isValue,
    monitoringPlan,
    orderLineItems,
    orderTotals: totals,
    promotionDiscounts: discounts.promotions,
    shippingMethod: cart.shippingMethod || 'FREE-Regular',
    totals: oldTotalsMap,
    isPayLater: false, // !DEPRECATED: left for legacy compatibility
  };
}

/* Additional Module Exports */
export const exportedForTesting = {
  applyInPlaceConclusiveDiscounts,
  applyInPlaceCoveCreditDiscounts,
  applyInPlaceCumulativeDiscounts,
  applyInPlaceEquipmentSubTotals,
  applyInPlaceFreeMonthsDiscounts,
  applyInPlaceMonitoringDiscounts,
  applyInPlaceMonitoringSubTotals,
  applyInPlaceOrderFinalTotals,
  applyInPlaceProductDiscounts,
  applyInPlaceProductFixedDiscount,
  applyInPlaceProductPercentDiscount,
  applyProductDiscountActivation,
  buildEquipmentLineItems,
  buildMonitoringLineItems,
  findAndActivateApplicableDiscounts,
  initializeCartTotals,
};

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { globalHistory } from '@reach/router';
import { CartProvider } from '@shared/cart-react';
import { ProductsProvider } from '@shared/react-product';
import { RouterProvider } from '@shared/react-router';
import { GlobalThemeProvider, Toaster } from '@shared/react-ui-core';
import { GatsbyBrowser, Link, navigate } from 'gatsby';
import 'promise-polyfill/src/polyfill';
import React from 'react';
import Scripts from './scripts';
import PageWrapper from './src/components/Layout/pageWrapper';
import PageLayout from './src/components/PageLayout';
import GatsbyBrowserStatus from './src/components/gatsby/BrowserStatus';
import { GatsbyButton, GatsbyLink } from './src/components/gatsby/Link';
import BaseProvider from './src/context/base';
import datoLoad from './src/context/datoLoad';
import { OrderProvider } from './src/context/order';
import { QaProvider } from './src/context/qa';
import analytics from './src/utils/analytics';
import config from './src/utils/config';

const { COVE_API_V4_URL } = config;
const env = process.env.GATSBY_API_ENV || process.env.NODE_ENV || 'development';

// Logs when the client route changes
export const onClientEntry: GatsbyBrowser['onClientEntry'] = async () => {
  analytics.page({
    isInitialPageLoad: true,
  });
};

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] =
  () => {
    /**
     * This is a workaround for a bug in Gatsby
     * Can remove once PR 803 is live
     *
     * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
     */
    // eslint-disable-next-line no-underscore-dangle
    globalHistory._onTransitionComplete();
  };

// Logs when the client route changes
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = async ({
  location,
  prevLocation,
}) => {
  if (prevLocation) {
    analytics.page({
      location,
      prevLocation,
    });
  }
  // console.log('onRouteUpdate Klaxon', {location, prevLocation})
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
  prevRouterProps,
}) => {
  if (
    !location.hash &&
    prevRouterProps?.location.pathname &&
    prevRouterProps?.location.pathname !== location.pathname
  ) {
    // need a short timeout to wait for the page to render
    setTimeout(() => {
      // Check if the browser is Safari
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent,
      );
      if (isSafari) {
        // Directly set the scroll position for Safari
        window.scroll(0, 0);
      } else {
        // Use smooth scrolling for other browsers
        window.scrollTo({ top: 0, behavior: 'instant' });
      }
    }, 10);

    return true;
  }

  return false;
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  ...restProps
}) => {
  const apiEnv =
    process.env.GATSBY_API_ENV || process.env.NODE_ENV || 'development';
  const partnerId = apiEnv !== 'production' ? '39770523' : '6400414';
  const datoToken = process.env.DATO_TOKEN || '7c8a71c5a466ac293a9f9f29233e78';
  const preview = apiEnv !== 'production';

  const urlParams = new URLSearchParams(window.location.search);
  const efTransactionId =
    urlParams.get('_ef_transaction_id') ||
    localStorage.getItem('ef_transaction_id');

  return (
    <PageWrapper>
      <RouterProvider navigate={navigate} Link={Link}>
        <Scripts env={env} />
        <GlobalThemeProvider
          overwriteTheme={{
            components: {
              MuiButtonBase: {
                defaultProps: {
                  LinkComponent: GatsbyButton,
                },
              },
              MuiLink: {
                defaultProps: {
                  component: GatsbyLink,
                },
              },
            },
          }}
        >
          <BaseProvider>
            <CartProvider
              apiV4Url={COVE_API_V4_URL}
              datoData={datoLoad()}
              efTransactionId={efTransactionId}
            >
              <ProductsProvider
                partnerId={partnerId}
                datoToken={datoToken}
                preview={preview}
              >
                <OrderProvider>
                  <QaProvider>
                    <PageLayout {...restProps.props}>{element}</PageLayout>
                  </QaProvider>
                </OrderProvider>
              </ProductsProvider>
            </CartProvider>
          </BaseProvider>
        </GlobalThemeProvider>
        <Toaster />
        <GatsbyBrowserStatus />
      </RouterProvider>
    </PageWrapper>
  );
};

/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import { Backdrop, Modal, Fade } from '@mui/material';
import x from '../../svgs/x.svg';
import {
  modalContent,
  modalChildren,
  modalCloseIcon,
  roundedModalStyle,
  roundedCloseIconStyle,
} from './style';
import Button from '../Button/index';
import { PropsWithChildren, ComponentProps } from 'react';

export type CoveModalProps = ComponentProps<typeof Modal> & {
  open: boolean;
  onClose: () => void;
  rounded?: boolean;
  modalContentOverwrite?: SerializedStyles;
  modalCss?: SerializedStyles;
  modalChildrenOverwrite?: SerializedStyles;
  childrenCss?: SerializedStyles;
  closeIconCss?: SerializedStyles;
};

const CoveModal: React.FC<PropsWithChildren<CoveModalProps>> = ({
  open,
  onClose,
  children,
  rounded = false,
  modalContentOverwrite,
  modalCss,
  modalChildrenOverwrite,
  childrenCss,
  closeIconCss,
  ...props
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...props}
    >
      <Fade in={open}>
        <div
          css={css`
            ${modalContentOverwrite || modalContent}
            ${rounded ? roundedModalStyle : ''}
            ${modalCss}
          `}
        >
          <Button
            cypressId="close-modal-button"
            style="text"
            css={css`
              ${modalCloseIcon}
              ${rounded ? roundedCloseIconStyle : ''}
              ${closeIconCss}
              z-index: 1;
            `}
            className='absolute p-0'
            onClick={onClose}
          >
            <img src={x} alt="close modal" />
          </Button>
          <div
            css={css`
              ${modalChildrenOverwrite || modalChildren};
              ${childrenCss}
            `}
          >
            {children}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default CoveModal;

export const getCookie = (name: string) => {
  if (typeof document !== 'undefined') {
    const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? v[2] : null;
  }
  return null;
};

export const hashSha256 = async (value: string) => {
  const utf8 = new TextEncoder().encode(value);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map(bytes => bytes.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

import { Script } from 'gatsby';
import setCTIDAndPesistCookies from './CTIDandPersistCookies';
import fb from './fb';
import getSnippet from './getSnippet';
import globals from './globals';

const Scripts = ({ env }) => {
  const hostname =
    typeof window !== 'undefined' ? window.location.hostname : '';
  const isWwwDotCovesmartDotCom = hostname === 'www.covesmart.com';
  const hostNameEnv =
    env === 'production' && isWwwDotCovesmartDotCom ? 'production' : '';
  const snippet = getSnippet(hostNameEnv);
  const CTIDandPersistCookies = setCTIDAndPesistCookies(hostNameEnv);

  const friendBuyScript = `
    window["friendbuyAPI"] = friendbuyAPI = window["friendbuyAPI"] || [];

    friendbuyAPI.merchantId = "65cf869f-1fea-46b9-b80e-fa97dcc5b790";
    friendbuyAPI.push(["merchant", friendbuyAPI.merchantId]);

    (function(f, r, n, d, b, u, y) {
      while ((u = n.shift())) {
        (b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
        b.async = 1;
        b.src = u;
        y.parentNode.insertBefore(b, y);
      }
    })(document, "script", [
      "https://static.fbot.me/friendbuy.js",
      "https://campaign.fbot.me/" + friendbuyAPI.merchantId + "/campaigns.js",
    ]);
  `

  return (
    <>
      <Script
        key="snippet"
        type={'text/javascript'}
        dangerouslySetInnerHTML={{ __html: snippet }}
        onError={() => console.log(`failure loading snippet`)}
      />
      {hostNameEnv === 'production' && (
        <Script
          key="fb"
          async
          type={'text/javascript'}
          dangerouslySetInnerHTML={{ __html: fb }}
          onError={() => console.log(`failure loading fb`)}
        />
      )}
      {hostNameEnv === 'production' && (
        <Script
          key="upfluence"
          type={'text/javascript'}
          src="https://tracking.upfluence.co/js/v1/init-7006294c-5fc7-4d97-a0af-739c77bbcbb9.js"
          onError={() => console.log(`failure loading upfluence tracking`)}
        />
      )}
      <Script
        async
        key="kalviyo"
        type={'text/javascript'}
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=V3n3ta"
        onError={() => console.log(`failure loading klaviyo`)}
      />
      <Script
        key="friendBuy"
        type={'text/javascript'}
        onLoad={() => console.log(`success loading friendBuy`)}
        onError={() => console.log(`failure loading friendBuy`)}
        dangerouslySetInnerHTML={{ __html: friendBuyScript }}
      />
      <Script
        key="cookies"
        defer
        type={'text/javascript'}
        dangerouslySetInnerHTML={{ __html: CTIDandPersistCookies }}
        onError={() => console.log(`failure loading cookies`)}
      />
      <Script
        key="youtube"
        type={'text/javascript'}
        src="https://www.youtube.com/iframe_api"
        onError={() => console.log(`failure loading youtube iframe`)}
      />
      <Script
        type={'text/javascript'}
        key="googlebadge"
        async
        src="https://apis.google.com/js/platform.js?onload=renderBadge"
        onError={() => console.log(`failure loading googlebadge`)}
      />
      <Script
        dangerouslySetInnerHTML={{ __html: globals }}
        onError={() => console.log(`failure loading globals`)}
      />
    </>
  );
};

export default Scripts;

import axios, { AxiosError } from 'axios';

const onError = (error: AxiosError) => error;

const getHeaders = (headers: Record<string, string> = {}) => ({
  'content-type': 'application/json',
  ...headers,
});

export const apiV4 = ({
  baseURL,
  method,
  url,
  body,
  headers,
}: {
  baseURL: string;
  method: 'post' | 'get' | 'patch' | 'delete';
  url: string;
  body: any;
  headers?: Parameters<typeof getHeaders>[0];
}) =>
  axios
    .request({
      baseURL,
      method,
      url,
      data: body,
      headers: getHeaders(headers),
    })
    .catch(onError);

import React, { PropsWithChildren, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export interface Posts {
  edges: {
    node: {
      id: string;
      title: string;
      subtitle: string;
      author: string;
      category: {
        id: string;
        name: string;
        slug: string;
        categoryType: string;
      }[];
      blogMetadata: {
        title: string;
        description: string;
        image: {
          url: string;
        };
      };
      postDate: string;
      blogSlug: string;
      primaryImage: {
        url: string;
        alt: string;
      };
      summary: string;
    };
  }[];
}
export interface Categories {
  edges: {
    node: {
      id: string;
      name: string;
      slug: string;
      categoryType: string;
    };
  }[];
}

export interface PostsContextType {
  posts?: Posts | null;
  categories?: Categories | null;
  allResourceArticles?: Posts['edges'];
  allResourceCategories?: Categories['edges'];
}

export const PostsContext = React.createContext<PostsContextType>({});

export const PostsProvider: React.FC<PropsWithChildren> = (props) => {
  const data = useStaticQuery<{ posts: Posts; categories: Categories }>(graphql`
    query PostsQuery {
      posts: allDatoCmsBlog(sort: { position: DESC }) {
        edges {
          node {
            id
            title
            subtitle
            author
            category {
              id
              name
              slug
              categoryType
            }
            blogMetadata {
              title
              description
              image {
                url
              }
            }
            postDate
            blogSlug
            primaryImage {
              url
              alt
            }
            summary
          }
        }
      }

      categories: allDatoCmsCategory(sort: { position: ASC }) {
        edges {
          node {
            id
            name
            slug
            categoryType
          }
        }
      }
    }
  `);
  const allResourceArticles = useMemo(
    () =>
      data?.posts?.edges?.filter?.(
        ({ node }) => node?.category?.[0]?.categoryType === 'resource'
      ) || [],
    [data?.posts?.edges]
  );
  const allResourceCategories = useMemo(
    () => [
      ...(data?.categories?.edges?.filter?.(
        ({ node }) => node?.categoryType === 'resource'
      ) || []),
    ],
    [data?.categories?.edges]
  );

  return (
    <PostsContext.Provider
      value={{ ...(data || {}), allResourceArticles, allResourceCategories }}
      {...props}
    />
  );
};

export const usePosts = () => {
  const context = React.useContext(PostsContext);
  if (context === undefined) {
    throw new Error(`usePosts must be used within a PostsProvider`);
  }
  return context;
};

export interface WithPostsProps {
  posts: PostsContextType | null;
}

export const withPosts =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P & WithPostsProps) => {
    return <Component {...props} posts={usePosts()} />;
  };

const PageWrapper = ({ children, ...props }) => {
  try {
    return <div {...props}>{children}</div>;
  } catch (error) {
    if (error.name === 'ChunkLoadError') {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } else {
      console.log(error);
      throw error;
    }
  }
};

export default PageWrapper;

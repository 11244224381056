const setCTIDAndPesistCookies = (env) => {
  const endpoint =
    env === 'production'
      ? 'https://api.covesmart.com/v4/c'
      : 'https://api.covestage.com/v4/c';

  const domain = env === 'production' ? 'covesmart.com' : 'covestage.com';

  return `
    try {
    function getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length == 2) return parts.pop().split(";").shift();
    }
    
    function persistCookies(cookies) {
      // Change these
      var endpoint = '${endpoint}';
      var domain = '${domain}';
    
    
      // Don't touch
      var safariApiPoll = getCookie('_safari_api_poll');
    
      // Only run if Safari hasn't been polled in the last 6 days
      if (safariApiPoll) {
          return null;
      }
    
      var data = JSON.stringify(cookies.map(function (cookie_name) {
          return {
              name: cookie_name,
              value: getCookie(cookie_name),
              options: {
                  path: '/',
                  domain: domain,
                  maxAge: 1000 * 60 * 60 * 24 * 365 * 2
              }
          }
      }))
      var xhr = new XMLHttpRequest();
      xhr.open('POST', endpoint, true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.withCredentials = true;
      xhr.send(data);
      document.cookie = '_safari_api_poll=true; domain=' + domain + '; path=/; max-age=' + 60*60*24*6;
    }
    
    function getQueryParam(name) {
      name = name.replace(/[\\[]/, '\\\\[').replace(/[\\]]/, '\\\\]');
        var regex = new RegExp('[\\\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\\+/g, ' '));
    }
    
    function getCTIDFromReferrer() {
      if (!document.referrer) return '26071';
      switch(document.referrer) {
          case 'https://www.google.com/': return '26052';
          case 'https://www.yahoo.com/': return '26069';
          case 'https://www.bing.com/': return '26004';
          default: return '26070';
      }
    }
    
    function setCTID() {
      var ctid = getCookie('ctid')
      var updateCookie = !ctid;
    
      if (!ctid) {
          ctid = getQueryParam('ctid')
      }
    
      if (!ctid) {
          ctid = getCTIDFromReferrer()
      }
    
      if (ctid && updateCookie) {
          var d = new Date();
          d.setTime(d.getTime() + (730*24*60*60*1000)); // 2 years
          var expires = d.toUTCString();
          var ctid_ts = (new Date).toISOString()
          var ctid_page = location.hostname+location.pathname
          document.cookie = 'ctid='+ctid+'; expires='+expires+'; domain=.covesmart.com; path=/';
          document.cookie = 'ctid_ts='+ctid_ts+'; expires='+expires+'; domain=.covesmart.com; path=/';
          document.cookie = 'ctid_page='+ctid_page+'; expires='+expires+'; domain=.covesmart.com; path=/';
      }
    }
    
    function cookieInit() {
      setCTID()
      persistCookies(['_ga', 'ctid', 'ctid_ts', 'ctid_page', 'gclid', 'fbclid'])
    }
    
    cookieInit()
    
    } catch (error) {
      console.error('sticky Cookie error', error);
    }
  `;
};

export default setCTIDAndPesistCookies;

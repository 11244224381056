/** @jsxImportSource @emotion/react */
import { type FC } from 'react';
import { type SerializedStyles, css } from '@emotion/react';
import {
  Divider as MuiDivider,
  type DividerProps as MuiDividerProps,
} from '@mui/material';
import { dividerStyle, hideMobile, hideDesktop } from './style';

export interface DividerProps extends MuiDividerProps {
  showOnMobile?: boolean;
  showOnDesktop?: boolean;
  css?: SerializedStyles;
}

const Divider: FC<DividerProps> = ({
  showOnMobile = true,
  showOnDesktop = true,
  css: propsCss,
  ...props
}) => {
  return (
    <MuiDivider
      css={css`
        ${dividerStyle}
        ${showOnMobile ? '' : hideMobile};
        ${showOnDesktop ? '' : hideDesktop};
        ${propsCss}
      `}
      {...props}
    />
  );
};
export default Divider;

export const getUrlParameter = (name) => {
  const newName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${newName}=([^&#]*)`);

  let results = null;
  if (typeof window !== `undefined` && typeof window.location !== 'undefined') {
    results = regex.exec(window.location.search);
  }

  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const setCookie = (name, value, days) => {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
};

export const getCookie = (name) => {
  if (typeof document !== 'undefined') {
    const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? v[2] : null;
  }
  return null;
};

export const toTitleCase = (str) =>
  str?.replace?.(
    /\w\S*/g,
    (txt) =>
      txt?.charAt?.(0)?.toUpperCase?.() + txt?.substr?.(1)?.toLowerCase?.()
  );

export const numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isMobile = () => {
  const smallScreenSize = 576; // $width-sm = 576px;
  return typeof window === 'undefined' || typeof window.screen === 'undefined'
    ? false
    : window.innerWidth < smallScreenSize;
};

export const breakPoints = {
  xs: 319,
  smd: 374,
  sm: 576,
  md: 767,
  lg: 991,
  lg2Below: 1099.98,
  lg2: 1100,
  xl: 1200,
  xxl: 2100,
};

export const isBelowBreakpoint = (breakpoint) => {
  return typeof window === 'undefined' || typeof window.screen === 'undefined'
    ? false
    : window.innerWidth < breakpoint;
};

export const printNumber = (num) => {
  if (num % 1 === 0) {
    return num;
  }
  return num.toFixed(2);
};

export const isMobileUserAgent = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const getNumberWithOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd'];

  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getDate = () => {
  const day = new Date().getDate();
  if (day > 28) {
    return '1st';
  }
  return getNumberWithOrdinal(day);
};

export const isAfterHours = (dateTime = new Date()) => {
  const time = dateTime.getHours();
  const openTime = 9;
  const closeTime = 18;

  const dayOfWeek = dateTime.getDay();
  const closedDays = [0, 6]; // Sunday and Saturday

  return closedDays.includes(dayOfWeek) || time < openTime || time >= closeTime;
};

export const isChatAvailable = () => {
  // Get date in MST
  const now = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'America/Denver' })
  );
  // Get current hour in MST
  const hour = now.getHours();
  // Get day of week
  const dayOfWeek = now.getDay();

  // Check we aren't outside business hours for chat
  if ((dayOfWeek === 6 && hour >= 18) || (dayOfWeek === 0 && hour < 7)) {
    return false;
  }
  return true;
};

export const createTypes = (namesArr) => {
  const types = {};
  return (
    namesArr?.reduce?.((acc, val) => {
      acc[val] = val;
      return acc;
    }, types) || types
  );
};


export const getLongMonthDayYear = (d) => {
  const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = new Date(d);
  const month = MONTHS[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

/**
 * @param {unknown} value
 * @returns {boolean}
 */
export const parseBoolean = (value) => {
  if (typeof value === 'number') return Boolean(value);
  if (typeof value === 'boolean') return value;
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true' || value.toLowerCase() === '1';
  }
  return false;
};

export const hashSha256 = async (string) => {
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

/**
 * Formats a number to a currency string.
 *  e.g. 99.00 -> $99
 *       | 99.5 -> $99.50
 *       | 1200 -> $1,200.00
 *
 * @param {Number} amount - number to format
 * @param {Object} [options] - options object
 * @param {Boolean} [options.force_decimals] options.force_decimals - force decimals to be shown
 * @param {Number} [options.decimals] options.decimals - number of decimals to show
 * @param {String} [options.currency_code] options.currency_code - currency code
 * @returns {String} - formatted currency e.g. $1,000.00
 */
export const format_currency = (
  amount,
  options = { force_decimals: false, decimals: 2, currency_code: 'USD' }
) => {
  if (typeof amount !== 'number') {
    amount = parseFloat(amount);

    if (isNaN(amount)) return '';
  }

  let { force_decimals, decimals, currency_code } = options;

  if (!force_decimals) {
    if (Number.isSafeInteger(amount)) decimals = 0;
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency_code,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(amount);
};

const globals = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){
    try{
      console.log('gtag() successfully called with these args:', {arguments})
      dataLayer.push(arguments);
    } catch (err) {
      console.log('failed to call gtag()', {err, arguments})
    }
  }
  gtag('js', new Date());
  if(!window.analytics) window.analytics = { track: () => {}};
`;

export default globals;

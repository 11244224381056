import { css } from '@emotion/react';
import { theme } from '../../../theme';

const { sm } = theme.breakpoints;

const dividerStyle = css`
  width: calc(100% - 100px);
  margin: 0 auto;
  max-width: 1440px;

  @media (max-width: ${`${sm}px`}) {
    width: calc(100% - 50px);
  }
`;

const hideMobile = css`
  @media (max-width: ${`${sm}px`}) {
    display: none;
  }
`;

const hideDesktop = css`
  display: none;

  @media (max-width: ${`${sm}px`}) {
    display: block;
  }
`;

export { dividerStyle, hideMobile, hideDesktop };

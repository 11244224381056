import React, { useState, useEffect } from 'react';

const GatsbyBrowserStatus: React.FC = () => {
  const [gatsbyBrowserStatusId, setGatsbyBrowserStatusId] = useState(
    'gatsby-browser-loading'
  );
  const isBrowser = typeof window !== 'undefined';
  useEffect(() => {
    if (isBrowser && gatsbyBrowserStatusId === 'gatsby-browser-loading') {
      setGatsbyBrowserStatusId('gatsby-browser-ready');
    }
  }, [isBrowser, gatsbyBrowserStatusId]);

  return <div id={gatsbyBrowserStatusId} />;
};

export default GatsbyBrowserStatus;

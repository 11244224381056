/** @jsxImportSource @emotion/react */
import { ThemeProvider, Typography, TypographyProps } from '@mui/material';
import { forwardRef } from 'react';
import H2 from '../H2';
import getTypographyTheme from '../typographyTheme';

interface Props extends TypographyProps {
  component?: React.ElementType;
  seoH1?: boolean;
  children?: React.ReactNode;
}

const H1 = forwardRef<HTMLHeadingElement, Props>(
  ({ children, seoH1, component, ...props }, ref) => {
    const finalComponent = component || seoH1 ? undefined : H2;
    const theme = getTypographyTheme();

    return (
      <ThemeProvider theme={theme}>
        <Typography
          variant="h1"
          {...{ ...props, component: finalComponent }}
          ref={ref}
        >
          {children}
        </Typography>
      </ThemeProvider>
    );
  }
);

export default H1;

/** @jsxImportSource @emotion/react */
import { ThemeProvider, Typography, TypographyProps } from '@mui/material';
import { forwardRef } from 'react';
import getTypographyTheme from '../typographyTheme';

interface Props extends TypographyProps {
  children?: React.ReactNode;
  component?: React.ElementType;
}

const H4 = forwardRef<HTMLHeadingElement, Props>(
  ({ children, ...props }, ref) => {
    const theme = getTypographyTheme();
    return (
      <ThemeProvider theme={theme}>
        <Typography variant="h4" {...props} ref={ref}>
          {children}
        </Typography>
      </ThemeProvider>
    );
  }
);

export default H4;

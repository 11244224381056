declare global {
  interface Window {
    Twilio: {
      FlexWebChat: object;
    };
  }
}
const brandColor1 = '#E4E9F0';
const brandColor2 = '#F2EFEB';
const brandTextColor1 = '#333333';
const brandTextColor2 = '#333333';


const personalizedColors = {
  darkBlueBackground: '#002334',
  whiteText: '#FFFFFF',
  entryPointBackground: '#002334',
  lighterBackground: '#FFFFFF',
  primaryButtonBackground: '#1976d2',
  primaryButtonColor: '#FFFFFF',
  secondaryButtonBackground: '#6e7180',
  secondaryButtonColor: '#FFFFFF'
};

// assign 
const brandMessageBubbleColors = (bgColor, brandTextColor) => ({
  Bubble: {
    background: bgColor,
    color: brandTextColor
  },
  Avatar: {
    display: 'none',
  },
  Header: {
    color: brandTextColor
  }
});

const brandedColors = {
  Chat: {
      MessageListItem: {
          FromOthers: brandMessageBubbleColors(brandColor1, brandTextColor1),
          FromMe: brandMessageBubbleColors(brandColor2, brandTextColor2),
      },
      MessageInput: {
          Container:{
              Button: {
                  background: brandColor1,
                  color: brandTextColor1
              }
          }
      },
      MessageCanvasTray: {
          Container: {
              background: personalizedColors.darkBlueBackground,
              color: personalizedColors.whiteText
          }
      }
  },
  MainContainer: {
    'z-index': 223
  },
  MainHeader: {
      Container: {
          background: personalizedColors.darkBlueBackground,
          color: personalizedColors.whiteText
      },
      Logo: {
          fill: brandTextColor1
      }
  },

  EntryPoint: {
      Container: {
          background: personalizedColors.entryPointBackground,
          color: personalizedColors.whiteText
      }
  },

  PreEngagementCanvas: {
      Container: {
          background: personalizedColors.lighterBackground
      },

      Form: {
          SubmitButton: {
              background: personalizedColors.primaryButtonBackground,
              color: personalizedColors.whiteText
          }
      }
  }
};

async function loadTwilioFlexChatWidget() {
  const appConfig = {
    accountSid: 'ACfcf60d2070b2960d32a1cac915fb33ab',
    flexFlowSid: 'FO2ad31791a41bd8ef19c8aabaab27fac6',
    startEngagementOnInit: false,
    context: {
      friendlyName: 'Anonymous'
    },
    colorTheme: {
      overrides: brandedColors
    },
    componentProps: {
      MainContainer: {
        width: '340px',
        height: '660px'
      },
      MainHeader: {
        Container: {
          background: '#002334',
          color: '#FFFFFF'
        },
        showTitle: true,
        titleText: 'Welcome to Cove!',
        showImage: true,
        imageUrl: 'https://www.datocms-assets.com/10154/1714773993-cove-logo-dark-avatar.png',
      },
    },
    preEngagementConfig: {
      description: 'Please provide some information',
      fields: [
          {
              label: 'What is your name?',
              type: 'InputItem',
              attributes: {
                  name: 'friendlyName',
                  type: 'text',
                  required: true
              }
          },
          {
              label: 'Do you have an account with us?',
              type: 'SelectItem',
              attributes: {
                  name: 'agentQueue',
                  required: true,
                  readOnly: false
              },
              options: [
                {
                    value: "TS/CS",
                    label: "Yes",
                    selected: true
                },
                {
                    value: "Sales",
                    label: "No",
                    selected: false
                }
                ]
          }, 
          {
              label: 'What can we help you with?',
              type: 'TextareaItem',
              attributes: {
                  name: 'question',
                  type: 'text',
                  placeholder: 'Type your question here',
                  required: false,
                  rows: 5
              }
          }, 
      ],
      submitLabel: "Ok Let's Go!"
    }
  }

  const twilioFlexScript = window.document.createElement('script');
  twilioFlexScript.id = 'twilioFlexChatWidget';
  twilioFlexScript.src =
    'https://assets.flex.twilio.com/releases/flex-webchat-ui/2.9.3/twilio-flex-webchat.min.js';
  twilioFlexScript.crossOrigin = 'anonymous';
  twilioFlexScript.integrity =
    'sha512-lL9Ihx5WpKGmP1wzno3O9BMWAnKJDxfNfoE7/HnVfESbtBAzA6jUhAOU+b4Nq3WvZthSf7mOH3SNo7+zVP7BVQ==';
  twilioFlexScript.addEventListener('load', function () {
    Twilio.FlexWebChat.createWebChat(appConfig).then(webchat => {
    const { manager } = webchat;

      Twilio.FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = false;
      Twilio.FlexWebChat.Actions.invokeAction("ToggleChatVisibility");
      // Posting question from preengagement form as users first chat message
      Twilio.FlexWebChat.Actions.on("afterStartEngagement", (payload) => {
        const { question } = payload.formData;
        if (!question)
            return;

        const { channelSid } = manager.store.getState().flex.session;
        manager
            .chatClient.getChannelBySid(channelSid)
            .then(channel => channel.sendMessage(question));
      });
    // Changing the Welcome message
    manager.strings.WelcomeMessage = "Thank you for Contacting Cove!";

    // Render WebChat
    webchat.init();
    });
  });
  window.document.head.appendChild(twilioFlexScript);

  return true;
}

export async function openChat() {
  if (!window?.Twilio?.FlexWebChat) {
    console.log('Opening Chat...');
    await loadTwilioFlexChatWidget();
    return true;
  }
  Twilio.FlexWebChat.Actions.invokeAction("ToggleChatVisibility");
}

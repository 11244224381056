import keyBy from 'lodash/keyBy';
import memoize from 'lodash/memoize';

const apiEnv =
  (typeof window !== 'undefined' && process.env.GATSBY_API_ENV) ||
  'development';

const token = '7c8a71c5a466ac293a9f9f29233e78';
const datoLoad = async (loadCached = true) => {
  try {
    const query = /* GraphQL */ `
    query DataLoadQuery {
      partner(filter: {partnerId: {eq: "${
        apiEnv !== 'production' ? '39770523' : '6400414'
      }"}}) {
        productsList {
          ...productFields
        }
        plansList {
          ...productFields
        }
      }
      partnerSalesConfig(filter: {partner: {eq: "${
        apiEnv !== 'production' ? '39770523' : '6400414'
      }"}}) {
        discountsList {
          id
          active
          activateCondition
          appliesTo
          conditionProductsQuantifier
          conditionProducts {
            sku
          }
          description
          minimumOrderTotal
          minimumOrderTotalAfterDiscount
          freeMonths
          exclusiveDiscount
          maxQuantity
          discountType
          discountAmount
          removeFromBase
          removeFromSalePrice
          priority
          restrictTo {
            id
            sku
          }
          additionalDiscounts {
            id
          }
          applyAfterDiscounts {
            id
          }
          overrideDiscounts {
            id
          }
        }
      }

      allProductCategories(first: 100) {
        id
        name
      }
      payLater {
        maximum
        minimum
        restrictTo {
          id
          sku
        }
      }
    }

    fragment productFields on ProductRecord {
      name
      sku
      createdAt
      pandoId
      productType
      price
      salePrice
      payLaterEligible
      description
      featureList {
        description
      }
      maximumSensorCountOnSite
      recommendation
      floorplanImage {
        url
      }
      primaryImage {
        url
      }
      category {
        name
        id
      }
      lowInventoryIcon
      outOfStock
      outOfStockAvailabilityText
      position
      dimensionImage {
        url
      }
    }
    `;

    const result = await fetch('https://graphql.datocms.com/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query,
      }),
    }).then((res) => res.json());
    const { data, errors } = result;

    data.partnerSalesConfig.discountsList.map((discount) => {
      discount.restrictTo = discount.restrictTo.map((product) => product.sku);
      discount.conditionProducts = discount.conditionProducts.map(
        (product) => product.sku
      );
      return discount;
    });
    data.payLater.restrictTo = data.payLater
      ? data.payLater.restrictTo.map((product) => product.sku)
      : [];
    return {
      products: keyBy(
        [...data.partner.productsList, ...data.partner.plansList],
        'sku'
      ),
      discounts: data.partnerSalesConfig.discountsList,
      payLater: data.payLater,
    };
  } catch (error) {
    console.error(error);
    if (!loadCached) {
      return { error };
    }
    const cachedDatoData = require('./datoData');
    return { error, ...(loadCached ? cachedDatoData : {}) };
  }
};

export default memoize(datoLoad);

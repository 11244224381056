import { css } from '@emotion/react';
import { theme } from '../../../theme';

const { primary, background } = theme.palette;
const { md, sm } = theme.breakpoints.values;
const { shadows } = theme;

const modalContent = css`
  background: ${background.gray3};
  width: calc(100% - 100px);
  max-width: 1266px;
  max-height: calc(100vh - 100px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: ${shadows[1]};
  overflow-y: auto;

  @media (max-width: ${`${md}px`}) {
    width: calc(100% - 50px);
    padding: 50px 25px;
  }
  @media (max-width: ${`${sm}px`}) {
    width: calc(100% - 30px);
    padding: 50px 25px;
  }
`;

const modalChildren = css`
  overflow-y: auto;
  margin: 10px;
  padding: 50px 0;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 15px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${primary.main};
    border-radius: 3px;
  }
`;

const modalCloseIcon = css`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  min-width: unset;
`;

const roundedModalStyle = css`
  border: 1px solid ${background.gray};
  box-shadow: ${shadows[1]};
  border-radius: 15px;
  background: white;
`;

const roundedCloseIconStyle = css`
  right: 25px;
  top: 20px;
`;

export {
  modalContent,
  modalChildren,
  modalCloseIcon,
  roundedModalStyle,
  roundedCloseIconStyle,
};

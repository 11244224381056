import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  FC,
} from 'react';

import { localStore } from '@shared/storage-js';
import { getUrlParameter, parseBoolean } from '../libs/utils';

export const QA_FLAG = 'QA_FLAG';

export interface QaContextValue {
  getQA: () => boolean;
  setQA: (qa: boolean) => void;
}

const QaContext = createContext<QaContextValue | null>(null);

function useQa() {
  const context = useContext(QaContext);
  if (!context) {
    throw new Error(`useQa must be used within a QaProvider`);
  }
  return context;
}

function QaProvider({ ...props }) {
  const qaParameter = getUrlParameter('qa');

  const getInitialQA = () => {
    if (qaParameter) {
      return qaParameter;
    }
    return localStore.getItem(QA_FLAG);
  };

  const [QA, setQAState] = useState<boolean>(
    parseBoolean(getInitialQA()) || false
  );
  useEffect(() => {
    const setFlag = !!QA || (!QA && localStore.getItem(QA_FLAG));
    if (setFlag) localStore.setItem(QA_FLAG, !!QA);
  }, [QA]);
  const setQA = useCallback(
    (qa: boolean) => setQAState(parseBoolean(qa)),
    [setQAState]
  );
  useEffect(() => {
    if (qaParameter) {
      setQA(parseBoolean(qaParameter));
    }
  }, [setQA, qaParameter]);

  const getQA = useCallback(() => QA, [QA]);

  const value = useMemo(() => ({ getQA, setQA }), [getQA, setQA]);
  return <QaContext.Provider value={value} {...{ ...props }} />;
}

function withQa<T>(Component: FC<T>) {
  const WrappedComponent: FC<T> = (props) => {
    return <Component {...props} Qa={useQa()} />;
  };
  return WrappedComponent;
}

export { QaProvider, useQa, withQa };

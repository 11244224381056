/* eslint-disable no-console */
import React, { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { localStore } from '../libs/storage';

const OrderContext = React.createContext();

function useOrder() {
  const context = React.useContext(OrderContext);
  if (!context) {
    throw new Error(`useOrder must be used within a OrderProvider`);
  }
  return context;
}

function OrderProvider(props) {
  const ORDER =
    typeof window !== `undefined` ? localStore.getItem('ORDER') : undefined;

  let persistedOrder;
  try {
    persistedOrder = JSON.parse(ORDER);
  } catch {
    persistedOrder = {};
  }

  const [order, setOrder] = useState({});
  React.useEffect(() => {
    async function createOrder() {
      if (!isEmpty(order)) {
        // eslint-disable-next-line no-unused-expressions
        typeof window !== `undefined` &&
          localStore.setItem('ORDER', JSON.stringify(order));
      } else {
        setOrder(persistedOrder);
      }
    }
    createOrder();
  }, [order ? order.orderNumber : order]);

  const contextValue = useMemo(
    () => ({
      data: order,
      setOrder,
    }),
    [order]
  );
  return <OrderContext.Provider value={contextValue} {...props} />;
}

function withOrder(Component) {
  return function WrappedComponent(props) {
    const order = useOrder();
    return <Component {...props} order={order} />;
  };
}

export { OrderProvider, useOrder, withOrder };

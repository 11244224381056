import { createTheme, useTheme } from '@mui/material';

const getTypographyTheme = () => {
  const currTheme = useTheme();
  const theme = createTheme({
    ...currTheme,
    typography: {
      // Fluid math from https://css-tricks.com/snippets/css/fluid-typography/
      // wrapped in clamp to prevent bigger or smaller sizes
      //
      // min = minimum size (in px);
      // max = maximum size (in px);
      // minREM = minimum size (in rem) (min / 16);
      // maxREM = maximum size (in rem) (max / 16);
      // minVP = minimum viewport width;
      // maxVP = maximum viewport width;
      //
      // clamp(minREM, calc(minREM + (max - min) * ((100vw - [minVP]px) / (maxVP - minVP))), maxREM)
      h1: {
        fontSize:
          'clamp(2.188rem, calc(2.188rem + (45 - 35) * ((100vw - 320px) / (900 - 320))), 2.813rem);',
        lineHeight:
          'clamp(2.5rem, calc(2.5rem + (52 - 40) * ((100vw - 320px) / (900 - 320))), 3.25rem);',
        fontWeight: '700',
        '@media (min-width: 900px)': {
          fontSize:
            'clamp(2.813rem, calc(2.813rem + (60 - 45) * ((100vw - 900px) / (1440 - 900))), 3.75rem);',
          lineHeight:
            'clamp(3.25rem, calc(3.25rem + (65 - 52) * ((100vw - 900px) / (1440 - 900))), 4.063rem);',
          fontWeight: '600',
        },
      },
      h2: {
        fontSize:
          'clamp(1.5rem, calc(1.5rem + (32 - 24) * ((100vw - 320px) / (900 - 320))), 2rem);',
        lineHeight:
          'clamp(1.875rem, calc(1.875rem + (39 - 30) * ((100vw - 320px) / (900 - 320))), 2.438rem);',
        fontWeight: '700',
        '@media (min-width: 900px)': {
          fontSize:
            'clamp(2rem, calc(2rem + (45 - 32) * ((100vw - 900px) / (1440 - 900))), 2.813rem);',
          lineHeight:
            'clamp(2.438rem, calc(2.438rem + (52 - 39) * ((100vw - 900px) / (1440 - 900))), 3.25rem);',
          fontWeight: '600',
        },
      },
      h3: {
        fontSize:
          'clamp(1.25rem, calc(1.25rem + (24 - 20) * ((100vw - 320px) / (900 - 320))), 1.5rem);',
        lineHeight:
          'clamp(1.75rem, calc(1.75rem + (30 - 28) * ((100vw - 320px) / (900 - 320))), 1.875rem);',
        fontWeight: '700',
        '@media (min-width: 900px)': {
          fontSize:
            'clamp(1.5rem, calc(1.5rem + (32 - 24) * ((100vw - 900px) / (1440 - 900))), 2rem);',
          lineHeight:
            'clamp(1.875rem, calc(1.875rem + (39 - 30) * ((100vw - 900px) / (1440 - 900))), 2.438rem);',
          fontWeight: '600',
        },
      },
      h4: {
        fontSize:
          'clamp(1rem, calc(1rem + (24 - 16) * ((100vw - 900px) / (1440 - 900))), 24px);',
        lineHeight:
          'clamp(20px, calc(20px + (30 - 20) * ((100vw - 900px) / (1440 - 900))), 30px);',
        fontWeight: '600',
        '@media (min-width: 900px)': {
          fontWeight: '600',
        },
      },
      h5: {
        fontSize: '1rem',
        lineHeight: '1.25rem',
        fontWeight: '600',
      },
      body1: {
        fontSize:
          'clamp(1rem, calc(1rem + (60 - 35) * ((100vw - 320px) / (1440 - 320))), 1.5rem);',
        lineHeight:
          'clamp(1.313rem, calc(1.313rem + (65 - 40) * ((100vw - 320px) / (1440 - 320))), 1.875rem);',
        fontWeight: '400',
      },
      body2: {
        fontSize: '1rem',
        lineHeight: '1.313rem',
        fontWeight: '400',
      },
      body3: {
        fontSize:
          'clamp(1rem, calc(1rem + (60 - 35) * ((100vw - 320px) / (1440 - 320))), 1.5rem);',
        lineHeight:
          'clamp(1.313rem, calc(1.313rem + (65 - 40) * ((100vw - 320px) / (1440 - 320))), 1.875rem);',
        fontWeight: '500',
        fontFamily: "'Poppins', sans-serif",
      },
      fontFamily: "'Poppins', sans-serif",
    },
  });
  return theme;
};

export default getTypographyTheme;

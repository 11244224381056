import Analytics from '@shared/analytics';
import config from './config';
import { localStore } from '@shared/storage-js';
import * as Sentry from '@sentry/browser';
import isUndefined from 'lodash/isUndefined';
import { v4 as uuid } from 'uuid';
import { getCookie, hashSha256 } from '../libs/utils';
import platform from 'platform';

const isBrowser = () => typeof window !== 'undefined';

function analyticsPluginGTM(useConfig) {
  return {
    name: 'gtm-analytics-plugin',
    initialize: ({ config }) => {
      if (isBrowser()) {
        window.gtmPluginLoaded = true;
      }
    },
    identify: async ({ payload }) => {
      try {
        const [sha256_email, sha256_phone_number] = await Promise.all([
          payload?.traits?.email
            ? async () =>
                hashSha256(payload.traits.email.toLowerCase().trim()).catch(
                  () => undefined
                )
            : undefined,
          payload?.traits?.phone
            ? async () =>
                hashSha256(
                  `+1${payload.traits.phone.split(/\D/).join('')}`
                ).catch(() => undefined)
            : undefined,
        ]);

        window.dataLayer.push({
          event: 'identify',
          ...JSON.parse(
            JSON.stringify({
              sha256_email,
              sha256_phone_number,
            })
          ),
          siteVersion: 3,
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    track: ({ payload }) => {
      const { event, properties: data = {} } = payload;

      const gtmEventMap = {
        add_payment_info: 'add_payment_info',
        add_shipping_info: 'add_shipping_info',
        addToCart: 'addToCart',
        begin_checkout: 'begin_checkout',
        checkout: 'checkout',
        continueToMonitoring: 'continueToMonitoring',
        CompleteRegistration: 'CompleteRegistration',
        generate_lead: 'generate_lead',
        identify: 'identify',
        InitiateCheckout: 'InitiateCheckout',
        login: 'login',
        'Popout Quiz - Collapse': 'Popout Quiz - Collapse',
        'Popout Quiz - Expand': 'Popout Quiz - Expand',
        'Popout Quiz - Close': 'Popout Quiz - Close',
        purchase: 'purchase',
        quiz: 'quiz',
        'quiz-completed': 'quiz-completed',
        quiz_finish: 'quiz_finish',
        quiz_progress: 'quiz_progress',
        quiz_start: 'quiz_start',
        quizAnswerSelected: 'quizAnswerSelected',
        quizResults: 'quizResults',
        'Quiz Results Mec - Close Sidebar': 'Quiz Results Mec - Close Sidebar',
        'Quiz Results Mec - Expand Sidebar':
          'Quiz Results Mec - Expand Sidebar',
        remove_from_cart: 'remove_from_cart',
        sign_up: 'sign_up',
        view_cart: 'view_cart',
        view_item: 'view_item',
      };
      const gtmEvent = gtmEventMap[event] || event;
      if (gtmEvent) {
        window.dataLayer.push({
          event: gtmEvent,
          ...data,
          siteVersion: 3,
        });
      }
    },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third-party
      return isBrowser() && !!window.gtmPluginLoaded && window.dataLayer;
    },
  };
}

function analyticsPluginCove(userConfig) {
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: 'cove-analytics-plugin',
    /* Everything else below this is optional depending on your plugin requirements */
    // config: {
    //   whatEver: userConfig.whatEver,
    //   elseYouNeed: userConfig.elseYouNeed
    // },
    initialize: ({ config }) => {
      // load provider script to page
      if (isBrowser()) {
        window.coveAnalyticsPluginLoaded = true;
        if (!localStore?.getItem?.('browser-id')) {
          localStore.setItem('browser-id', uuid());
        }
      }
    },
    page: ({ payload }) => {
      // call provider specific page tracking
      let currentUrlParams = {};
      let event = 'pageLoad';
      const eventId = payload?.meta?.rid;
      new URLSearchParams(payload?.properties?.search)?.forEach?.(
        (value, key) => {
          currentUrlParams[key] = value;
        }
      );
      const isLeadsTimerExpired =
        Number(new Date()) > Number(localStore?.getItem?.('leadsTimer'));
      if (payload?.properties?.payload?.isInitialPageLoad) {
        event = 'initialPageLoad';
      }

      const data = {
        browserId: localStore?.getItem?.('browser-id'),
        sessionId: payload?.anonymousId,
        url: payload?.properties?.url,
        path: payload?.properties?.path,
        ref: payload?.properties?.ref,
        user: JSON.parse(localStore?.getItem?.('customer-info'))?.values
          ?.customer1,
        address: JSON.parse(localStore?.getItem?.('customer-info'))?.values
          ?.monitorAddress,
        browser: { name: platform?.name, version: platform?.version },
        os: platform?.os,
      };
      coveEventTrack(eventId, event, data);
      if (
        currentUrlParams['salesRepId'] &&
        currentUrlParams['cartId'] &&
        isLeadsTimerExpired
      ) {
        const event = 'partnerLinkClicked';
        const eventId = uuid();
        coveEventTrack(eventId, event, data);
        localStore.setItem('leadsTimer', Number(new Date()) + 60 * 60 * 1000);
      }
      return;
    },
    track: ({ payload }) => {
      // call provider specific event tracking
      const eventId = payload?.meta?.rid;
      const { event, properties: data } = payload;

      if (event === 'quizAnswerSelected') {
        const { currentQuestion = {} } = data;
        data.answer =
          currentQuestion.type === 'checkbox'
            ? (currentQuestion.response || []).join(',')
            : currentQuestion?.response !== null ||
              currentQuestion?.response !== undefined
            ? currentQuestion.response
            : 'response not provided';

        const name = `Quiz - Question`;
        coveEventTrack(eventId, name, { event, ...data });
        delete data.cartId;
      } else if (event === 'quiz-completed') {
        coveEventTrack(eventId, event, { event, ...data });
      } else if (event === 'generate_lead') {
        coveEventTrack(eventId, event, { event, ...data });
      }
    },
    identify: ({ payload }) => {
      const event = 'identify';
      const eventId = payload?.meta?.rid;
      const data = {
        userId: payload?.userId,
        ...payload?.traits,
      };
      coveEventTrack(eventId, event, data);
    },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third-party
      return isBrowser() && !!window.coveAnalyticsPluginLoaded;
    },
  };
}

function analyticsPluginFacebook(useConfig) {
  return {
    name: 'facebook-analytics-plugin',
    track: ({ payload }) => {
      const eventId = payload?.meta?.rid;
      const { event, properties: data } = payload;
      const facebookEventMap = {
        // funnel events
        PageView: 'PageView',
        ViewContent: 'ViewContent',
        CustomizeProduct: 'CustomizeProduct',
        InitiateCheckout: 'InitiateCheckout',
        AddPaymentInfo: 'AddPaymentInfo',
        CompleteRegistration: 'CompleteRegistration',
        addToCart: 'AddToCart',
        AddToCart: 'AddToCart',
        purchase: 'Purchase',
        checkout: 'Checkout',
        // tyvm funnel events
        'TYVM Get Code': 'TYVM Get Code',
        'TYVM Redeem Code': 'TYVM Redeem Code',
        'TYVM Checkout': 'TYVM Checkout',
        // other events
        Lead: 'Lead',
      };

      const facebookEvent = facebookEventMap[event];

      if (facebookEvent) {
        const testEventCode =
          localStore?.getItem?.('fb_test_event_code') || null;
        const { email, ...fbData } = { ...data };
        const fbq = window.fbq;
        if (!isUndefined(fbq)) {
          try {
            fbq(
              'track',
              facebookEvent,
              { test_event_code: testEventCode, ...fbData },
              { eventID: eventId }
            );
          } catch (error) {
            Sentry.captureException(error);
          }
        }
        if (typeof facebookEvent === 'string') {
          const fbc = getCookie('_fbc') || null;
          const fbp = getCookie('_fbp') || null;
          const customerInfo = JSON.parse(
            localStore?.getItem?.('customer-info') || '{}'
          ).values;

          try {
            coveEventTrack(eventId, facebookEvent, {
              testEventCode,
              ...fbData,
              fbc,
              fbp,
              customerInfo,
              href: window.location.href,
            });
          } catch (error) {
            Sentry.captureException(error);
          }
        }
      }
    },
    loaded: () => {
      return isBrowser() && !!window.fbq;
    },
  };
}

function analyticsPluginDyno(useConfig) {
  return {
    name: 'dyno-analytics-plugin',
    page: ({ payload }) => {
      window?.dyno?.service?.trackPageView?.();
    },
    track: ({ payload }) => {
      const { event, properties } = payload;
      try {
        window?.dyno?.service?.track?.(event, {
          channels: [
            (session) => {
              return {
                type: 'browser',
                channel_id: session.browser_id,
              };
            },
          ],
          properties,
        });
      } catch (error) {
        Sentry.captureMessage(`Error dyno.service.track ${event}`);
        Sentry.captureException(error);
      }
    },
    loaded: () => {
      return isBrowser() && !!window.dyno;
    },
  };
}

const analytics = Analytics({
  app: 'website',
  version: '1.0.0',
  COVE_API_V4_URL: config.COVE_API_V4_URL,
  plugins: [
    analyticsPluginGTM(),
    analyticsPluginCove(),
    analyticsPluginFacebook(),
    analyticsPluginDyno(),
  ],
});

const coveEventTrack = (eventId, event, data) => {
  trackEvent({
    id: eventId,
    cartId: localStore?.getItem?.('CART_ID'),
    name: event,
    data,
    collectedAt: new Date(),
  })
    .then(() => {
      console.log(`event (${eventId}) - successful`);
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

const analyticsExports = {
  identify: (payload, options) => {
    const browserId = localStore?.getItem?.('browser-id');
    return analytics.identify(browserId, payload, options);
  },
  page: (...args) => analytics.page({ payload: args }),
  /**
   * @param {string} event
   * @param {Record<string, any>=} data
   * @param {any=} options
   */
  track: (event, data = {}, options) => analytics.track(event, data, options),
};

export default analyticsExports;

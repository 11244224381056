module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Covesmart","icon":"src/favicon.png","short_name":"Cove","start_url":"/","background_color":"#ffffff","theme_color":"#00b29c","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"64379b9dbf60bd4584e3c0185cbcac27"},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://02475b5fd3b9408486b33e2d85e0ad92@o282499.ingest.sentry.io/4505585500356608","environment":"production","enabled":true,"ignoreErrors":["ValidationError","AbortError","QuotaExceededError","AbortError",{},{},{},{},{},{},{},"TypeError: The network connection was lost.","TypeError: cancelled","Error: Request failed with status code 400","TypeError: NetworkError when attempting to fetch resource.","TypeError: The request timed out.","TypeError: Failed to fetch","TypeError: Preflight response is not successful","TypeError: Origin https://www.covesmart.com is not allowed by Access-Control-Allow-Origin.","TypeError: The operation couldn’t be completed. Software caused connection abort","Error: Network Error","Non-Error promise rejection captured","top.GLOBALS","originalCreateNotification","canvas.contentDocument","MyApp_RemoveAllHighlights","http://tt.epicplay.com","Can't find variable: ZiteReader","jigsaw is not defined","ComboSearch is not defined","http://loading.retry.widdit.com/","atomicFindClose","fb_xd_fragment","bmi_SafeAddOnload","EBCallBackMessageReceived","conduitPage"],"whitelistUrls":[{}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

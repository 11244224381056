// @ts-nocheck
/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useCart } from '@shared/cart-react';
import { useProducts } from '@shared/react-product';
import {
  Button,
  Divider,
  H1,
  H3,
  H4,
  Modal,
  Text,
  useWindowSize,
} from '@shared/react-ui-core';
import { graphql, useStaticQuery } from 'gatsby';
import { keyBy } from 'lodash';
import { useState, type FC, useEffect } from 'react';
import Imgix from 'react-imgix';

const WeveUpgradedOutEquipment: FC<{ onClose: () => void }> = ({
  onClose,
  ...props
}) => {
  const theme = useTheme();
  const products = useProducts();

  const data = useStaticQuery(graphql`
    query WeveUpgradedOutEquipmentComponentQuery {
      weveUpgradedOutEquipmentComponent: datoCmsComponent(
        name: { eq: "WeveUpgradedOutEquipment" }
      ) {
        images {
          name
          image {
            url
            alt
          }
        }
      }
    }
  `);

  const images = keyBy(data?.weveUpgradedOutEquipmentComponent?.images, 'name');

  const { width = 0 } = useWindowSize();

  const imageCardItemCss = css`
    display: flex;
    gap: ${theme.spacing(1)};
    width: 100%;
    align-items: center;
    @media (min-width: ${theme?.breakpoints?.values?.md}px) {
      width: min-content;
      flex-direction: column;
    }
  `;

  const imageCardItemImageCss = css`
    width: 52px;
    height: 52px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    * {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
    }
  `;

  const textCardItemCss = css`
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    @media (min-width: ${theme?.breakpoints?.values?.md}px) {
      font-weight: 400;
    }
  `;

  return (
    <center {...props}>
      <H1
        css={css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        We’ve upgraded our equipment!
      </H1>
      <Text
        variant="body2"
        css={css`
          margin-bottom: ${theme.spacing(2)};
          font-weight: 400;
        `}
      >
        Our brand new security system features dual connection with ethernet and
        cellular.
      </Text>

      <div
        css={css`
          margin-bottom: ${theme.spacing(2)};
          text-align: left;
          @media (min-width: ${theme?.breakpoints?.values?.md}px) {
            margin-bottom: ${theme.spacing(2)};
            text-align: center;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            gap: ${theme.spacing(0)};
            background: ${theme?.palette?.background?.gray3};
            padding: ${theme.spacing(1)};
            border-radius: ${theme.spacing(1)};
            box-shadow: ${theme.shadows[1]};
            @media (min-width: ${theme?.breakpoints?.values?.md}px) {
              display: flex;
              flex-direction: column;
              gap: ${theme.spacing(0)};
              background: unset;
              padding: unset;
              border-radius: unset;
              box-shadow: unset;
              justify-content: center;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${theme.spacing(0)};
              @media (min-width: ${theme?.breakpoints?.values?.md}px) {
                display: flex;
                flex-direction: column;
                width: fit-content;
                justify-content: stretch;
                margin: 0 auto;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${theme.spacing(1)};
                @media (min-width: ${theme?.breakpoints?.values?.md}px) {
                  all: unset;
                  display: flex;
                  width: min-content;
                  gap: ${theme.spacing(1)};
                  flex-direction: row;
                  justify-content: center;
                  margin-bottom: ${theme.spacing(3)};
                }
              `}
            >
              <div css={imageCardItemCss}>
                {width > theme?.breakpoints?.values?.md ? (
                  <Imgix
                    src={images['the-brains-of-the-system'].image.url}
                    height={126}
                    width={180}
                    htmlAttributes={{
                      alt: images['the-brains-of-the-system'].image.alt,
                    }}
                  />
                ) : (
                  <div css={imageCardItemImageCss}>
                    <Imgix
                      src={products['CPA-HUB-345-1'].primaryImage.url}
                      height={50}
                      width={51}
                      htmlAttributes={{
                        alt: images['the-brains-of-the-system'].image.alt,
                      }}
                    />
                  </div>
                )}
                <Text css={textCardItemCss}>The "brains" of the system</Text>
              </div>
              <div css={imageCardItemCss}>
                {width > theme?.breakpoints?.values?.md ? (
                  <Imgix
                    src={
                      images['install-up-to-4-touch-screen-alarm-panels'].image
                        .url
                    }
                    height={126}
                    width={180}
                    htmlAttributes={{
                      alt: images['install-up-to-4-touch-screen-alarm-panels']
                        .image.alt,
                    }}
                  />
                ) : (
                  <div css={imageCardItemImageCss}>
                    <Imgix
                      src={products['CPA-PANEL-345-1'].primaryImage.url}
                      height={30}
                      width={52}
                      htmlAttributes={{
                        alt: images['install-up-to-4-touch-screen-alarm-panels']
                          .image.alt,
                      }}
                    />
                  </div>
                )}
                <Text css={textCardItemCss}>
                  Install up to 4 touchscreen alarm panels
                </Text>
              </div>
              <div css={imageCardItemCss}>
                {width > theme?.breakpoints?.values?.md ? (
                  <Imgix
                    src={images['get-up-to-8-secondary-sirens'].image.url}
                    height={126}
                    width={180}
                    htmlAttributes={{
                      alt: images['get-up-to-8-secondary-sirens'].image.alt,
                    }}
                  />
                ) : (
                  <div css={imageCardItemImageCss}>
                    <Imgix
                      src={products['secondary-siren'].primaryImage.url}
                      height={43}
                      width={40}
                      htmlAttributes={{
                        alt: images['get-up-to-8-secondary-sirens'].image.alt,
                      }}
                    />
                  </div>
                )}
                <Text css={textCardItemCss}>Get up to 8 secondary sirens.</Text>
              </div>
            </div>
            {width > theme?.breakpoints?.values?.md && (
              <Divider
                css={css`
                  width: 100% !important;
                  flex-grow: 1;
                `}
              />
            )}
          </div>
        </div>
      </div>

      <H4
        css={css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        It looks like you’ve already built a system
      </H4>
      <Text
        variant="body2"
        css={css`
          margin-bottom: ${theme.spacing(2)};
          font-weight: 400;
        `}
      >
        We’ve updated your customized system to include our new Hub and Alarm
        Panel.
      </Text>
      <Button
        cypressId="continue-to-cart"
        style="outlined"
        color="purple"
        size="large"
        css={css`
          margin-bottom: ${theme.spacing(3)};
        `}
        onClick={() => onClose()}
        href="/quiz-results/"
      >
        Continue to Cart
      </Button>
      <Button
        cypressId="learn-more-about-the-hub"
        style="text"
        color="purple"
        size="large"
        onClick={() => onClose()}
        href="/products/alarm-panel-hub/"
      >
        Learn more about the Hub
      </Button>
    </center>
  );
};

const WeveUpgradedOutEquipmentModal: FC = () => {
  const [open, setOpen] = useState(false);
  const cart = useCart();
  useEffect(() => {
    if (!open && cart?.migratedToAlula) {
      setOpen(true);
    }
  }, [cart?.migratedToAlula, open]);

  const handleOnClose = () => {
    cart?.setMigratedToAlula?.(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      modalCss={css`
        background: white;
        padding: 50px 35px !important;
        max-width: 720px;
      `}
      childrenCss={css`
        padding: 0;
        margin: 0;
      `}
    >
      <WeveUpgradedOutEquipment onClose={handleOnClose} />
    </Modal>
  );
};
export default WeveUpgradedOutEquipmentModal;

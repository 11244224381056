const environments = {
  local: {
    MAGENTO_API_URL:
      'https://integration-5ojmyuq-ncl2dqg4a46fg.us-3.magentosite.cloud/rest/V1',
    COVE_API_V1_URL: 'http://localhost:3000',
    COVE_API_V4_URL: 'http://localhost:3010/staging',
  },
  development: {
    MAGENTO_API_URL:
      'https://integration-5ojmyuq-ncl2dqg4a46fg.us-3.magentosite.cloud/rest/V1',
    // TODO: Wait for v4 implementation
    COVE_API_V1_URL:
      'https://ogmrg5m8nb.execute-api.us-east-1.amazonaws.com/stage',
    COVE_API_V4_URL: 'https://api.covestage.com/v4',
  },
  pando: {
    MAGENTO_API_URL:
      'https://integration-5ojmyuq-ncl2dqg4a46fg.us-3.magentosite.cloud/rest/V1',
    // TODO: Wait for v4 implementation
    COVE_API_V1_URL:
      'https://6uavm1wurh.execute-api.us-east-1.amazonaws.com/pando',
    COVE_API_V4_URL:
      'https://6uavm1wurh.execute-api.us-east-1.amazonaws.com/pando/v4',
  },
  staging: {
    MAGENTO_API_URL:
      'https://mcstaging.covesmart.com.c.ncl2dqg4a46fg.ent.magento.cloud',
    // TODO: Wait for v4 implementation
    COVE_API_V1_URL:
      'https://ogmrg5m8nb.execute-api.us-east-1.amazonaws.com/stage',
    COVE_API_V4_URL: 'https://api.covestage.com/v4',
  },
  production: {
    MAGENTO_API_URL:
      'https://integration-5ojmyuq-ncl2dqg4a46fg.us-3.magentosite.cloud/rest/V1',
    // TODO: Wait for v4 implementation
    COVE_API_V1_URL:
      'https://tphushol7h.execute-api.us-east-1.amazonaws.com/production',
    COVE_API_V4_URL: 'https://api.covesmart.com/v4',
  },
};

const config =
  environments[process.env.GATSBY_API_ENV] || environments['development'];
export default config;

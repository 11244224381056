import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      colors: [
        'cove-green',
        'cove-forest-green',
        'cove-blue',
        'cove-navy-blue',
        'cove-dove',
        'cove-baby-dove',
        'cove-dark-grey',
        'cove-red',
        'cove-white',
      ],
      spacing: Array(10)
        .fill('')
        .map((_, i) => `cove-${i + 1}`),
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

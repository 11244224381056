/** @jsxImportSource @emotion/react */
import { ThemeProvider, Typography, TypographyProps } from '@mui/material';
import { forwardRef } from 'react';
import getTypographyTheme from '../typographyTheme';

interface Props extends TypographyProps {
  component?: string | React.ReactNode;
  variant?: 'body1' | 'body2' | 'body3';
  children?: React.ReactNode;
}

const Text = forwardRef<HTMLParagraphElement, Props>(
  ({ variant = 'body1', component = 'p', children, ...props }, ref) => {
    const theme = getTypographyTheme();
    return (
      <ThemeProvider theme={theme}>
        <Typography variant={variant} {...{ ...props, component }} ref={ref}>
          {children}
        </Typography>
      </ThemeProvider>
    );
  }
);

export default Text;
